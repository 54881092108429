import { type IIconName } from "@revana/ui";

interface IRouteMatch {
  appKey: AppsInNav;
  matchLength: number;
}

export function getCurrentApp(url: string): IAppInfo {
  const urlSegments = url.split("/").filter(Boolean);

  let closestMatch: IRouteMatch = {
    appKey: EApps.DASHBOARD,
    matchLength: 0,
  };

  (Object.entries(APP_ROUTES) as [AppsInNav, string][]).forEach(
    ([appKey, route]) => {
      const routeSegments = route.split("/").filter(Boolean);
      let matchLength = 0;

      for (
        let i = 0;
        i < Math.min(urlSegments.length, routeSegments.length);
        i++
      ) {
        if (urlSegments[i] === routeSegments[i]) {
          matchLength++;
        } else {
          break;
        }
      }

      if (matchLength > 0 && matchLength >= closestMatch.matchLength) {
        closestMatch = { appKey, matchLength };
      }
    }
  );

  return APP_INFO[closestMatch.appKey];
}

export function getCurrentSubApp(
  mainApp: IAppInfo,
  url: string
): ISubAppInfo | null {
  if (!mainApp.subApps) {
    return null;
  }

  const urlSegments = url.split("/").filter(Boolean);
  const mainAppSegments = mainApp.route.split("/").filter(Boolean);
  const mainAppPathLength = mainAppSegments.length;

  // Check if URL is long enough to contain a sub-app path
  if (urlSegments.length <= mainAppPathLength) {
    return null;
  }

  // Get the potential sub-app path segment
  const subAppPathSegment = urlSegments[mainAppPathLength];
  const matchingSubApp = mainApp.subApps.find(
    (sa) => sa.path === subAppPathSegment
  );

  return matchingSubApp ?? null;
}

export interface ISubAppInfo {
  path: string;
  displayName: string;
  subtitle?: string;
  icon?: IIconName;
  route: string;
  display: boolean;
}

export interface IAppInfo {
  key: EApps;
  displayName: string;
  subtitle?: string;
  icon?: IIconName;
  mainRoute: string;
  route: string;
  display: boolean;
  subApps?: ISubAppInfo[];
  isSubApp?: boolean;
  parentApp?: IAppInfo;
}

export enum EApps {
  /* Sidebar (Store) */
  DASHBOARD = "dashboard",
  CAMPAIGNS = "campaigns",
  REPORTS = "reports",
  STORE_SETTINGS = "store-settings",
  HELP = "help",

  /* Account */
  ONBOARDING = "onboarding",
  ACCOUNT_SETTINGS = "account-settings",

  /* Auth */
  SIGNUP = "signup",
  LOGIN = "login",
  INVITE_LOGIN = "invite-login",
  INVITE_SIGNUP = "invite-signup",
  SHOPIFY_LOGIN = "shopify-login",
  SHOPIFY_SIGNUP = "shopify-signup",

  /* Internal */
  ADMIN = "admin",
}

type AppsInNav = Exclude<
  EApps,
  | "signup"
  | "login"
  | "onboarding"
  | "invite-login"
  | "invite-signup"
  | "shopify-login"
  | "shopify-signup"
>;

export enum ESubApps {
  /* Store Settings */
  TEAM = "team",
  GENERAL = "general",
}

export const APP_ROUTES: Record<EApps, string> = {
  [EApps.DASHBOARD]: "/",
  [EApps.CAMPAIGNS]: "/campaigns",
  [EApps.REPORTS]: "/reports",
  [EApps.STORE_SETTINGS]: "/store-settings",
  [EApps.HELP]: "/help",

  /* Account */
  [EApps.ONBOARDING]: "/onboarding",
  [EApps.ACCOUNT_SETTINGS]: "/account-settings",

  /* Auth */
  [EApps.SIGNUP]: "/auth/signup",
  [EApps.LOGIN]: "/auth/login",
  [EApps.INVITE_LOGIN]: "/auth/login/invite",
  [EApps.INVITE_SIGNUP]: "/auth/signup/invite",
  [EApps.SHOPIFY_LOGIN]: "/auth/login/shopify",
  [EApps.SHOPIFY_SIGNUP]: "/auth/signup/shopify",

  /* Internal */
  [EApps.ADMIN]: "/admin",
};

export const SUBAPP_ROUTES: Record<ESubApps, string> = {
  /* Store Settings */
  [ESubApps.TEAM]: `${APP_ROUTES[EApps.STORE_SETTINGS]}/team`,
  [ESubApps.GENERAL]: `${APP_ROUTES[EApps.STORE_SETTINGS]}/general`,
};

export const SUBAPP_INFO: Record<ESubApps, ISubAppInfo> = {
  [ESubApps.TEAM]: {
    path: "team",
    displayName: "Team",
    subtitle: "Manage your store team",
    icon: "team",
    route: SUBAPP_ROUTES[ESubApps.TEAM],
    display: true,
  },
  [ESubApps.GENERAL]: {
    path: "general",
    displayName: "General",
    subtitle: "Basic store configuration",
    icon: "settings",
    route: SUBAPP_ROUTES[ESubApps.GENERAL],
    display: true,
  },
};

export const APP_INFO: Record<AppsInNav, IAppInfo> = {
  [EApps.DASHBOARD]: {
    key: EApps.DASHBOARD,
    displayName: "Dashboard",
    subtitle: "View your store analytics",
    icon: "circle-gauge",
    mainRoute: APP_ROUTES[EApps.DASHBOARD],
    route: APP_ROUTES[EApps.DASHBOARD],
    display: true,
  },
  [EApps.CAMPAIGNS]: {
    key: EApps.CAMPAIGNS,
    displayName: "Campaigns",
    subtitle: "Create your own AI generated music",
    icon: "megaphone",
    mainRoute: APP_ROUTES[EApps.CAMPAIGNS],
    route: APP_ROUTES[EApps.CAMPAIGNS],
    display: true,
  },
  [EApps.REPORTS]: {
    key: EApps.REPORTS,
    displayName: "Reports",
    subtitle: "View your store analytics",
    icon: "file-line-chart",
    mainRoute: APP_ROUTES[EApps.REPORTS],
    route: APP_ROUTES[EApps.REPORTS],
    display: false,
  },
  [EApps.STORE_SETTINGS]: {
    key: EApps.STORE_SETTINGS,
    displayName: "Store Settings",
    subtitle: "Store settings for Revana",
    icon: "settings",
    mainRoute: SUBAPP_ROUTES[ESubApps.TEAM],
    route: APP_ROUTES[EApps.STORE_SETTINGS],
    display: true,
    subApps: [SUBAPP_INFO[ESubApps.TEAM]],
  },
  [EApps.HELP]: {
    key: EApps.HELP,
    displayName: "Help",
    subtitle: "Get support from the Revana community",
    icon: "badge-help",
    mainRoute: APP_ROUTES[EApps.HELP],
    route: APP_ROUTES[EApps.HELP],
    display: false,
  },

  /* Account */
  [EApps.ACCOUNT_SETTINGS]: {
    key: EApps.ACCOUNT_SETTINGS,
    displayName: "Account Settings",
    subtitle: "Account settings for Revana",
    mainRoute: APP_ROUTES[EApps.ACCOUNT_SETTINGS],
    route: APP_ROUTES[EApps.ACCOUNT_SETTINGS],
    display: false,
  },

  /* Internal */
  [EApps.ADMIN]: {
    key: EApps.ADMIN,
    displayName: "Admin",
    subtitle: "Admin panel for Revana",
    mainRoute: APP_ROUTES[EApps.ADMIN],
    route: APP_ROUTES[EApps.ADMIN],
    display: false,
  },
};

export const APPS = Object.values(APP_INFO);
