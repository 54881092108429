import { z } from "zod";

import {
  dateRangeSchema,
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from "./date-range-schema";

export const sortOperatorEnum = z.enum(["asc", "desc"]);
export const sortColumnEnum = z.enum([
  "revenueRecovered",
  "totalPrice",
  "createdAt",
  "outreachStatus",
  "outreachResult",
  "phoneNumber",
]);

export const SortColumnSchema = z.object({
  column: sortColumnEnum.optional().default("createdAt"),
  sort: sortOperatorEnum.optional().default("desc"),
});

export type TSortColumn = z.infer<typeof SortColumnSchema>;

export const callResultEnum = z.enum([
  "ANSWERED",
  "VOICEMAIL",
  "NO_ANSWER",
  "UNKNOWN",
]);
export const messageResultsEnum = z.enum([
  "REPLIED",
  "READ",
  "DELIVERED",
  "FAILED",
]);

export const StatusEnum = z.enum([
  "IN_PROGRESS",
  "FAILED",
  "COMPLETED",
  "SCHEDULED",
]);

export const DEFAULT_CHECKOUTS_QUERY = {
  sort: {
    column: sortColumnEnum.enum.createdAt,
    sort: sortOperatorEnum.enum.desc,
  },
  filters: {
    status: [
      StatusEnum.Enum.IN_PROGRESS,
      StatusEnum.Enum.FAILED,
      StatusEnum.Enum.COMPLETED,
      StatusEnum.Enum.SCHEDULED,
    ],
    totalPrice: {
      min: undefined,
      max: undefined,
    },
    revenueRecovered: {
      min: undefined,
      max: undefined,
    },
  },
  page: 1,
  from: DEFAULT_START_DATE,
  to: DEFAULT_END_DATE,
};

export const DEFAULT_TOTAL_PRICE = {
  min: undefined,
  max: undefined,
};

export const DEFAULT_REVENUE_RECOVERED = {
  min: undefined,
  max: undefined,
};

export const CheckoutsFiltersSchema = z.object({
  status: StatusEnum.array().default(DEFAULT_CHECKOUTS_QUERY.filters.status),
  result: z
    .union([messageResultsEnum.array(), callResultEnum.array()])
    .optional(),
  totalPrice: z
    .object({
      min: z.number().optional(),
      max: z.number().optional(),
    })
    .default(DEFAULT_CHECKOUTS_QUERY.filters.totalPrice),
  revenueRecovered: z
    .object({
      min: z.number().optional(),
      max: z.number().optional(),
    })
    .default(DEFAULT_CHECKOUTS_QUERY.filters.revenueRecovered),
});

export type TCheckoutsFilters = z.infer<typeof CheckoutsFiltersSchema>;
export type TCheckoutsFilterField = keyof TCheckoutsFilters;

export const GetCheckoutsQuerySchema = z.object({
  campaignGroupId: z.string(),
  page: z.number().int().positive().default(DEFAULT_CHECKOUTS_QUERY.page),
  sort: SortColumnSchema.optional().default(DEFAULT_CHECKOUTS_QUERY.sort),
  filters: CheckoutsFiltersSchema.optional().default(
    DEFAULT_CHECKOUTS_QUERY.filters
  ),
  from: dateRangeSchema.shape.from.default(DEFAULT_CHECKOUTS_QUERY.from),
  to: dateRangeSchema.shape.to.default(DEFAULT_CHECKOUTS_QUERY.to),
});

export type TGetCheckoutsQuery = Omit<
  z.infer<typeof GetCheckoutsQuerySchema>,
  "campaignGroupId"
>;
