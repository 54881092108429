import {
  CheckoutsFiltersSchema,
  SortColumnSchema,
  DEFAULT_CHECKOUTS_QUERY,
  type TCheckoutsFilters,
  type TSortColumn,
} from "@revana/api/utils/checkouts-query";
import {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from "@revana/api/utils/date-range-schema/index";
import { createParser, parseAsInteger } from "nuqs/server";

const filterObjToString = (f: object) =>
  JSON.stringify(f, (_, value: unknown) => {
    if (value instanceof Date) {
      return value.toISOString();
    }
    return value;
  });

const parseFilters = createParser<TCheckoutsFilters>({
  parse: (rawData) => CheckoutsFiltersSchema.parse(JSON.parse(rawData)),
  serialize(objValue) {
    return JSON.stringify(
      objValue,
      (_, value: TCheckoutsFilters[keyof TCheckoutsFilters]) => {
        if (value instanceof Date) {
          return value.toISOString();
        }
        return value;
      }
    );
  },
  eq: (a, b) => filterObjToString(a) === filterObjToString(b),
});

const parseSortColumn = createParser<TSortColumn>({
  parse: (rawData) => SortColumnSchema.parse(JSON.parse(rawData)),
  serialize: (data) => filterObjToString(data),
  eq: (a, b) => a.column === b.column && a.sort === b.sort,
});

const dateParser = (range: "from" | "to") => {
  return createParser<string>({
    parse: (date) => {
      try {
        const now = new Date(date);
        if (isNaN(now.getTime())) {
          throw new Error("Invalid date");
        }
        return date;
      } catch (error) {
        return range === "from" ? DEFAULT_START_DATE : DEFAULT_END_DATE;
      }
    },
    serialize: (date) => {
      try {
        const now = new Date(date);
        if (isNaN(now.getTime())) {
          throw new Error("Invalid date");
        }
        return date;
      } catch (error) {
        return range === "from" ? DEFAULT_START_DATE : DEFAULT_END_DATE;
      }
    },
    eq: (a, b) => {
      try {
        return new Date(a).getTime() === new Date(b).getTime();
      } catch (error) {
        return (!a && !b) || a === b || false;
      }
    },
  });
};

const dateRangeQueryParsers = {
  from: dateParser("from").withDefault(DEFAULT_START_DATE),
  to: dateParser("to").withDefault(DEFAULT_END_DATE),
};

const checkoutFilterQueryParsers = {
  page: parseAsInteger.withDefault(DEFAULT_CHECKOUTS_QUERY.page),
  filters: parseFilters.withDefault(DEFAULT_CHECKOUTS_QUERY.filters),
  sort: parseSortColumn.withDefault(DEFAULT_CHECKOUTS_QUERY.sort),
  from: dateRangeQueryParsers.from.withDefault(DEFAULT_CHECKOUTS_QUERY.from),
  to: dateRangeQueryParsers.to.withDefault(DEFAULT_CHECKOUTS_QUERY.to),
};

export { checkoutFilterQueryParsers, dateRangeQueryParsers };
