"use client";

import { useCallback, useEffect, useState } from "react";

import { useQueryStates } from "nuqs";

import { checkoutFilterQueryParsers } from "@web/libs/nuqs-search-params/constants";
import { getNestedValue, setNestedValue } from "@web/utils";

import { DEFAULT_CHECKOUTS_QUERY, type TSortColumn } from "./types";

export const useCheckoutsQuery = () => {
  const [checkoutsQuery, setCheckoutsQuery] = useQueryStates(
    checkoutFilterQueryParsers
  );

  const isEqual = useCallback(
    (filters: typeof checkoutsQuery) => {
      return Object.keys(checkoutFilterQueryParsers).every((key) => {
        type FilterKey = keyof typeof checkoutsQuery;
        type Filter = (typeof checkoutsQuery)[FilterKey];
        const valA = checkoutsQuery[key];
        const valB = filters[key];

        const compareFunc = checkoutFilterQueryParsers[key].eq as (
          a: Filter,
          b: Filter
        ) => boolean;

        return compareFunc(valA, valB);
      });
    },
    [checkoutsQuery]
  );

  const resetFilters = useCallback(() => {
    void setCheckoutsQuery(null);
  }, [setCheckoutsQuery]);

  return {
    checkoutsQuery,
    setCheckoutsQuery,
    resetFilters,
    isEqual,
  };
};

export const useCheckoutsQueryState = <
  K extends Flatten<typeof checkoutsQuery>,
>(
  filterKey: K
): [
  /**
   * The current value of the filter
   */
  NestedValue<typeof checkoutsQuery, K>,
  /**
   * Updates the filter
   * @param payload - The payload to update the filter with
   * @param apply - Whether to apply the update to the query state
   */
  (
    payload: Partial<NestedValue<typeof checkoutsQuery, K>>,
    apply?: boolean
  ) => void,
  /**
   * Resets the filter to the default state
   * @param apply - Whether to apply the reset to the query state
   */
  (apply?: boolean) => void,
  /**
   * Resets all filters to the default state
   * @param apply - Whether to apply the reset to the query state
   */
  (apply?: boolean) => void,
] => {
  const [checkoutsQuery, setCheckoutsQuery] = useQueryStates(
    checkoutFilterQueryParsers
  );

  // Reset the page to 1 when the from or to date changes
  useEffect(() => {
    void setCheckoutsQuery({
      ...checkoutsQuery,
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutsQuery.from, checkoutsQuery.to]);

  const [localCheckoutsQuery, setLocalCheckoutsQuery] =
    useState<typeof checkoutsQuery>(checkoutsQuery);

  const getValue = useCallback(
    <T extends Flatten<typeof checkoutsQuery>>(key: T) => {
      return getNestedValue(localCheckoutsQuery, key);
    },
    [localCheckoutsQuery]
  );

  const updateQuery = useCallback(
    (
      payload: Partial<NestedValue<typeof checkoutsQuery, K>>,
      apply = false
    ) => {
      const updatedCheckoutsQuery = setNestedValue(
        localCheckoutsQuery,
        filterKey,
        payload
      );

      // Reset the page to 1 when any param other than page changes
      if (filterKey !== "page" && apply) {
        updatedCheckoutsQuery.page = 1;
      }

      setLocalCheckoutsQuery(updatedCheckoutsQuery);

      if (apply || filterKey === "page") {
        void setCheckoutsQuery(updatedCheckoutsQuery);
      }
    },
    [filterKey, localCheckoutsQuery, setCheckoutsQuery]
  );

  useEffect(() => {
    setLocalCheckoutsQuery(checkoutsQuery);
  }, [checkoutsQuery]);

  const resetFilter = useCallback(
    (apply = false) => {
      const defaultState =
        getNestedValue(
          DEFAULT_CHECKOUTS_QUERY as typeof checkoutsQuery,
          filterKey
        ) ?? undefined;
      const updatedCheckoutsQuery = setNestedValue(
        localCheckoutsQuery,
        filterKey,
        defaultState
      );

      setLocalCheckoutsQuery(updatedCheckoutsQuery);

      if (apply) {
        void setCheckoutsQuery(updatedCheckoutsQuery);
      }
    },
    [filterKey, localCheckoutsQuery, setCheckoutsQuery]
  );

  const resetAllFilters = useCallback(
    (apply = true) => {
      setLocalCheckoutsQuery(DEFAULT_CHECKOUTS_QUERY);

      if (apply) {
        void setCheckoutsQuery(DEFAULT_CHECKOUTS_QUERY);
      }
    },
    [setCheckoutsQuery]
  );

  const currentValue = getValue(filterKey);

  return [currentValue, updateQuery, resetFilter, resetAllFilters];
};

export const useCheckoutsQuerySort = () => {
  const [sortedColumn, setSortedColumn] = useCheckoutsQueryState("sort");

  const sortColumn = useCallback(
    (column: TSortColumn["column"]) => {
      setSortedColumn(
        {
          column: column,
          sort: sortedColumn?.sort === "asc" ? "desc" : "asc",
        },
        true
      );
    },
    [setSortedColumn, sortedColumn?.sort]
  );

  const getSortDirection = useCallback(
    (column: TSortColumn["column"]) => {
      return sortedColumn?.column === column ? sortedColumn?.sort : null;
    },
    [sortedColumn?.column, sortedColumn?.sort]
  );

  return { sortedColumn, sortColumn, getSortDirection };
};
