"use client";

import { useCallback } from "react";

import {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from "@revana/api/utils/date-range-schema/index";
import { type DateRange } from "@revana/ui";
import { endOfDay, startOfDay } from "date-fns";
import { useQueryStates } from "nuqs";

import { dateRangeQueryParsers } from "@web/libs/nuqs-search-params/constants";

const convertDate = (date: Date | undefined, range: "from" | "to"): string => {
  if (!date) {
    return range === "from" ? DEFAULT_START_DATE : DEFAULT_END_DATE;
  }
  const dateToConvert = range === "from" ? startOfDay(date) : endOfDay(date);
  return dateToConvert.toISOString();
};

const useDateRangeQuery = () => {
  const [queryDateRange, setQueryDateRange] = useQueryStates(
    dateRangeQueryParsers
  );

  const setDateRange = useCallback(
    (newDateRange: DateRange | undefined) => {
      void setQueryDateRange({
        from: convertDate(newDateRange?.from, "from"),
        to: convertDate(newDateRange?.to, "to"),
      });
    },
    [setQueryDateRange]
  );

  const isEqual = useCallback(
    (dateRange1: typeof queryDateRange, dateRange2: typeof queryDateRange) => {
      return (
        dateRangeQueryParsers.from.eq(dateRange1.from, dateRange2.from) &&
        dateRangeQueryParsers.to.eq(dateRange1.to, dateRange2.to)
      );
    },
    []
  );

  return {
    setDateRange: setDateRange,
    dateRange: queryDateRange,
    isEqual: isEqual,
  };
};

export default useDateRangeQuery;
